import React from 'react';
import { useNavigate } from 'react-router-dom';
import { FaFacebookF, FaTwitter, FaInstagram, FaMapMarkerAlt, FaPhone, FaEnvelope } from 'react-icons/fa';

const Footer: React.FC = () => {
  const navigate = useNavigate();

  const handleNavigation = (path: string) => {
    navigate(path);
    window.scrollTo(0, 0);
    if (path === '/careers') {
      window.location.reload();
    }
  };

  const footerLinks = [
    { title: "Quick Links", links: [
      { path: '/home', label: 'Home' },
      { path: '/about', label: 'About' },
      { path: '/services', label: 'Services' },
      { path: '/eap', label: 'EAP' },
      { path: '/blog', label: 'Blog' },
    ]},
    { title: "Resources", links: [
      { path: '/member', label: 'EAP Member Login' },
      { path: '/contact', label: 'Contact' },
      { path: '/careers', label: 'Careers' },
      { path: '/terms', label: 'Terms of Use' },
    ]},
  ];

  const socialLinks = [
    { href: 'https://facebook.com', icon: FaFacebookF, label: 'Facebook' },
    { href: 'https://twitter.com', icon: FaTwitter, label: 'Twitter' },
    { href: 'https://instagram.com', icon: FaInstagram, label: 'Instagram' },
  ];

  return (
    <footer className="bg-customTextColor text-white w-full pt-12 pb-6 px-4 sm:px-6">
      <div className="max-w-7xl mx-auto">
        <div className="grid grid-cols-1 md:grid-cols-4 gap-8 mb-8">
          {/* Company Info */}
          <div className="col-span-1">
            <h3 className="text-xl font-bold mb-4">Paragon Wellness Center</h3>
            <div className="space-y-3 text-gray-300">
              <p className="flex items-center gap-2">
                <FaMapMarkerAlt className="flex-shrink-0" />
                <span>Philadelphia, PA</span>
              </p>
              <p className="flex items-center gap-2">
                <FaPhone className="flex-shrink-0" />
                <span>(215)-PWC-6040</span>
              </p>
              <p className="flex items-center gap-2">
                <FaEnvelope className="flex-shrink-0" />
                <span>support@paragonwellness.com</span>
              </p>
            </div>
          </div>

          {/* Navigation Links */}
          {footerLinks.map((section, index) => (
            <div key={index} className="col-span-1">
              <h3 className="text-lg font-semibold mb-4">{section.title}</h3>
              <div className="space-y-2">
                {section.links.map(({ path, label }) => (
                  <button
                    key={path}
                    onClick={() => handleNavigation(path)}
                    className="block text-gray-300 hover:text-white transition-colors duration-200"
                  >
                    {label}
                  </button>
                ))}
              </div>
            </div>
          ))}

          {/* Social Media and Hours */}
          <div className="col-span-1">
            <h3 className="text-lg font-semibold mb-4">Connect With Us</h3>
            <div className="flex gap-4 mb-6">
              {socialLinks.map(({ href, icon: Icon, label }) => (
                <a
                  key={label}
                  href={href}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="bg-white/10 p-2 rounded-full hover:bg-white/20 transition-colors duration-200"
                  aria-label={label}
                >
                  <Icon size={20} />
                </a>
              ))}
            </div>
            <div className="text-gray-300">
              <h4 className="font-semibold mb-2">Hours of Operation</h4>
              <p>Monday - Friday: 9:00 AM - 5:00 PM</p>
              <p>Saturday - Sunday: Closed</p>
            </div>
          </div>
        </div>

        {/* Copyright */}
        <div className="border-t border-white/10 pt-6 mt-8 text-center text-gray-400">
          <p>&copy; {new Date().getFullYear()} Paragon Wellness Center LLC. All rights reserved.</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
