import React from 'react';
import { Helmet } from 'react-helmet';
import BackToTopButton from './BackToTopButton';

const Member: React.FC = () => {
  return (
    <>
      <Helmet>
        <title>Member Portal - Paragon Wellness Center</title>
        <meta name="description" content="Access your personalized EAP member portal at Paragon Wellness Center. Track your progress and explore exclusive wellness resources." />
        <link rel="canonical" href="https://www.paragonwellness.org/member" />
      </Helmet>
      
      <section className="min-h-screen overflow-y-auto bg-gradient-to-b from-white via-beige to-gray-50 text-customTextColor py-20">
        <div className="container mx-auto px-4 lg:px-8">
          <div className="bg-white rounded-2xl shadow-xl p-8 lg:p-12">
            <h1 className="text-4xl lg:text-5xl font-playfair font-bold mb-6 text-customTextColor text-center">
              Paragon Wellness EAP Member Portal
            </h1>
            <div className="h-1 w-52 bg-gradient-to-r from-burntOrange to-oliveGreen rounded-full mx-auto mb-8"></div>
            <p className="text-xl mb-12 max-w-3xl mx-auto text-gray-700 text-center italic">
              Access your personalized wellness dashboard and explore exclusive content designed to enhance your well-being journey.
            </p>
            
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-12 mb-16">
              {/* Login Section */}
              <div className="bg-gradient-to-br from-white to-beige p-8 rounded-2xl shadow-lg">
                <h2 className="text-2xl font-playfair font-bold mb-6 text-burntOrange">EAP Member Login</h2>
                <p className="text-gray-700 mb-8">
                  Track your progress, access resources, and continue your wellness journey through our secure member portal.
                </p>
                <button 
                  onClick={() => window.open('https://paragoneap.mylifeexpert.com/', '_blank', 'noopener,noreferrer')}
                  className="group bg-burntOrange hover:bg-oliveGreen text-white px-8 py-3 rounded-full font-bold transition-all duration-300 transform hover:-translate-y-1 flex items-center justify-center"
                >
                  <span className="group-hover:tracking-wider transition-all duration-300">Access Member Portal</span>
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 ml-2" viewBox="0 0 20 20" fill="currentColor">
                    <path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd" />
                  </svg>
                </button>
              </div>

              {/* Video Section */}
              <div className="bg-gradient-to-br from-white to-beige p-8 rounded-2xl shadow-lg">
                <h2 className="text-2xl font-playfair font-bold mb-6 text-oliveGreen">Portal Tutorial</h2>
                <div className="aspect-w-16 aspect-h-9 mb-6">
                  <video
                    className="rounded-lg w-full h-full object-cover"
                    controls
                    poster="/service-images/thumbnail.jpg"
                  >
                    <source src="/logos/member-login-video.mp4" type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                </div>
                <p className="text-gray-700">
                  Watch this quick tutorial to learn how to navigate and make the most of your member portal experience.
                </p>
              </div>
            </div>

            {/* Feature Highlights */}
            <div className="mt-16">
              <h2 className="text-3xl font-playfair font-bold mb-6 text-customTextColor text-center">EAP Member Benefits</h2>
              <div className="h-1 w-40 bg-gradient-to-r from-burntOrange to-oliveGreen rounded-full mx-auto mb-12"></div>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                <FeatureCard 
                  title="Personalized Wellness Plans" 
                  description="Access customized health and wellness plans tailored to your unique needs and goals. Our expert team creates programs designed specifically for you."
                  icon="🎯"
                />
                <FeatureCard 
                  title="Expert Resources" 
                  description="Explore our extensive library of articles, videos, and tools curated by wellness professionals. Stay informed with the latest health and wellness insights."
                  icon="📚"
                />
              </div>
            </div>

            {/* Getting Started Section */}
            <div className="mt-16 bg-gradient-to-br from-customTextColor to-customHoverColor text-white p-8 lg:p-12 rounded-2xl shadow-xl">
              <h2 className="text-3xl font-playfair font-bold mb-6 text-center">Getting Started</h2>
              <div className="bg-white/10 p-8 rounded-xl backdrop-blur-sm max-w-2xl mx-auto">
                <ol className="list-decimal list-inside space-y-4">
                  <li>Log in to your account using the button above</li>
                  <li>Complete your wellness profile to receive personalized recommendations</li>
                  <li>Explore the dashboard and familiarize yourself with the available tools</li>
                  <li>Set your wellness goals and track your progress</li>
                  <li>Engage with the community and participate in challenges</li>
                </ol>
                <p className="mt-6">
                  Need help? Contact our support team at{' '}
                  <a href="mailto:support@paragonwellness.org" className="text-burntOrange hover:text-white transition-colors duration-300">
                    support@paragonwellness.org
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
        <BackToTopButton />
      </section>
    </>
  );
};

const FeatureCard: React.FC<{ title: string; description: string; icon: string }> = ({ title, description, icon }) => (
  <div className="bg-gradient-to-br from-white to-beige p-8 rounded-2xl shadow-lg transition-all duration-300 hover:-translate-y-2">
    <div className="text-5xl mb-6">{icon}</div>
    <h3 className="text-2xl font-playfair font-bold mb-4 text-burntOrange">{title}</h3>
    <p className="text-gray-700">{description}</p>
  </div>
);

export default Member;
