import React, { useState, useRef, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Banner from './Banner';

const Navbar: React.FC = () => {
  const [isSticky, setIsSticky] = useState(false);
  const [isMobileNavVisible, setIsMobileNavVisible] = useState(false);
  const [isMobileNavVisibleOnScroll, setIsMobileNavVisibleOnScroll] = useState(true);
  const [isMainNavVisible, setIsMainNavVisible] = useState(true);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [scrollDirection, setScrollDirection] = useState<'up' | 'down' | null>(null);
  const [lastScrollY, setLastScrollY] = useState(0);
  const bannerRef = useRef<HTMLDivElement>(null);
  const navbarRef = useRef<HTMLDivElement>(null);
  const menuRef = useRef<HTMLDivElement>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);
  const navigate = useNavigate();

  useEffect(() => {
    function handleScroll() {
      const currentScrollY = window.scrollY;
      if (bannerRef.current) {
        const bannerBottom = bannerRef.current.offsetHeight;
        setIsSticky(currentScrollY > bannerBottom);
      }

      if (currentScrollY > lastScrollY && currentScrollY > 100) {
        setScrollDirection('down');
        setIsMobileNavVisibleOnScroll(false);
        setIsMenuOpen(false);
      } else if (currentScrollY < lastScrollY) {
        setScrollDirection('up');
        setIsMobileNavVisibleOnScroll(true);
      }

      setLastScrollY(currentScrollY);
    }

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [lastScrollY]);

  useEffect(() => {
    function handleResize() {
      const isMobile = window.innerWidth < 768;
      setIsMobileNavVisible(isMobile);
      setIsMainNavVisible(!isMobile);
    }

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (menuRef.current && !menuRef.current.contains(event.target as Node) &&
          buttonRef.current && !buttonRef.current.contains(event.target as Node)) {
        setIsMenuOpen(false);
      }
    }

    if (isMenuOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isMenuOpen]);

  const handleNavigation = (path: string) => {
    navigate(path);
    setIsMenuOpen(false); // Close menu when navigating
    window.scrollTo(0, 0); // Scroll to top of the page
  };

  const toggleMenu = (event: React.MouseEvent) => {
    event.stopPropagation();
    setIsMenuOpen(prevState => !prevState);
  };

  const closeMenu = () => {
    setIsMenuOpen(false);
  };

  return (
    <>
      {/* Banner */}
      <Banner isVisible={!isMobileNavVisible && !isMenuOpen} bannerRef={bannerRef} />

      {/* Desktop Nav */}
      <nav
        ref={navbarRef}
        className={`bg-beige p-4 w-full z-50 transition-all duration-300 ease-in-out ${
          isSticky ? 'sticky top-0 shadow-lg' : 'relative'
        } ${isMainNavVisible ? 'block' : 'hidden'}`}
      >
        <div className="max-w-7xl mx-auto">
          <div className="flex justify-between items-center">
            <div className="flex items-center">
              <button onClick={() => handleNavigation('/home')} className="flex items-center space-x-2 hover:opacity-80 transition-opacity">
                <img src="/logos/logoIcon.png" alt="logo" className="h-14 w-auto" />
              </button>
            </div>
            <div className="hidden md:flex items-center space-x-8">
              <button 
                onClick={() => handleNavigation('/home')} 
                className="text-customTextColor text-lg font-semibold hover:text-customHoverColor transition-colors duration-200 relative group"
              >
                Home
                <span className="absolute bottom-0 left-0 w-0 h-0.5 bg-customHoverColor transition-all duration-300 group-hover:w-full"></span>
              </button>
              <button 
                onClick={() => handleNavigation('/about')} 
                className="text-customTextColor text-lg font-semibold hover:text-customHoverColor transition-colors duration-200 relative group"
              >
                About
                <span className="absolute bottom-0 left-0 w-0 h-0.5 bg-customHoverColor transition-all duration-300 group-hover:w-full"></span>
              </button>
              <button 
                onClick={() => handleNavigation('/services')} 
                className="text-customTextColor text-lg font-semibold hover:text-customHoverColor transition-colors duration-200 relative group"
              >
                Services
                <span className="absolute bottom-0 left-0 w-0 h-0.5 bg-customHoverColor transition-all duration-300 group-hover:w-full"></span>
              </button>
              <button 
                onClick={() => handleNavigation('/eap')} 
                className="text-customTextColor text-lg font-semibold hover:text-customHoverColor transition-colors duration-200 relative group"
              >
                EAP
                <span className="absolute bottom-0 left-0 w-0 h-0.5 bg-customHoverColor transition-all duration-300 group-hover:w-full"></span>
              </button>
              <button 
                onClick={() => handleNavigation('/blog')} 
                className="text-customTextColor text-lg font-semibold hover:text-customHoverColor transition-colors duration-200 relative group"
              >
                Blog
                <span className="absolute bottom-0 left-0 w-0 h-0.5 bg-customHoverColor transition-all duration-300 group-hover:w-full"></span>
              </button>
              <button 
                onClick={() => handleNavigation('/contact')} 
                className="text-customTextColor text-lg font-semibold hover:text-customHoverColor transition-colors duration-200 relative group"
              >
                Contact
                <span className="absolute bottom-0 left-0 w-0 h-0.5 bg-customHoverColor transition-all duration-300 group-hover:w-full"></span>
              </button>
              <button 
                onClick={() => handleNavigation('/member')} 
                className="text-customTextColor text-lg font-semibold hover:text-customHoverColor transition-colors duration-200 relative group"
              >
                EAP Member Login
                <span className="absolute bottom-0 left-0 w-0 h-0.5 bg-customHoverColor transition-all duration-300 group-hover:w-full"></span>
              </button>
            </div>
          </div>
        </div>
      </nav>

      {/* Mobile Nav */}
      <nav
        className={`md:hidden fixed top-0 w-full bg-beige z-50 transition-all duration-300 ease-in-out ${
          isMobileNavVisible ? (isMobileNavVisibleOnScroll ? 'translate-y-0' : '-translate-y-full') : 'hidden'
        } shadow-lg`}
      >
        <div className="flex justify-between items-center p-4">
          <button
            ref={buttonRef}
            onClick={toggleMenu}
            className="text-customTextColor p-2 rounded-lg hover:bg-paddingBackground transition-colors duration-200"
          >
            {isMenuOpen ? '\u2715' : '\u2630'}
          </button>
          
          <Link to="/home" className="flex flex-col items-center" onClick={() => handleNavigation('/home')}>
            <img src="/logos/fullLogo.png" alt="logo" className="h-12 w-auto" />
            <span className="text-customTextColor italic text-xs underline mt-2">
              Opening Soon
            </span>
          </Link>
          <div className="w-10"></div>
        </div>

        {/* Mobile Nav Menu */}
        {isMenuOpen && (
          <div 
            ref={menuRef} 
            className="fixed top-[80px] left-0 w-64 bg-beige shadow-xl rounded-br-lg overflow-hidden transition-all duration-300"
          >
            <div className="p-4 space-y-2">
              <button 
                onClick={() => handleNavigation('/home')} 
                className="w-full text-left px-4 py-3 text-lg font-semibold text-customTextColor hover:bg-paddingBackground hover:text-customHoverColor transition-colors duration-200 rounded-lg"
              >
                Home
              </button>
              <button 
                onClick={() => handleNavigation('/about')} 
                className="w-full text-left px-4 py-3 text-lg font-semibold text-customTextColor hover:bg-paddingBackground hover:text-customHoverColor transition-colors duration-200 rounded-lg"
              >
                About
              </button>
              <button 
                onClick={() => handleNavigation('/services')} 
                className="w-full text-left px-4 py-3 text-lg font-semibold text-customTextColor hover:bg-paddingBackground hover:text-customHoverColor transition-colors duration-200 rounded-lg"
              >
                Services
              </button>
              <button 
                onClick={() => handleNavigation('/eap')} 
                className="w-full text-left px-4 py-3 text-lg font-semibold text-customTextColor hover:bg-paddingBackground hover:text-customHoverColor transition-colors duration-200 rounded-lg"
              >
                EAP
              </button>
              <button 
                onClick={() => handleNavigation('/blog')} 
                className="w-full text-left px-4 py-3 text-lg font-semibold text-customTextColor hover:bg-paddingBackground hover:text-customHoverColor transition-colors duration-200 rounded-lg"
              >
                Blog
              </button>
              <button 
                onClick={() => handleNavigation('/contact')} 
                className="w-full text-left px-4 py-3 text-lg font-semibold text-customTextColor hover:bg-paddingBackground hover:text-customHoverColor transition-colors duration-200 rounded-lg"
              >
                Contact
              </button>
              <button 
                onClick={() => handleNavigation('/member')} 
                className="w-full text-left px-4 py-3 text-lg font-semibold text-customTextColor hover:bg-paddingBackground hover:text-customHoverColor transition-colors duration-200 rounded-lg"
              >
                EAP Member Login
              </button>
            </div>
          </div>
        )}
      </nav>
    </>
  );
};

export default Navbar;