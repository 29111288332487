import React, { useEffect, useRef } from 'react';
import Slider from 'react-slick';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import BackToTopButton from './BackToTopButton';
import { Helmet } from 'react-helmet';


const Services: React.FC = () => {
  const { hash } = useLocation();
  const observerRef = useRef<IntersectionObserver | null>(null);

  useEffect(() => {
    if (hash) {
      const element = document.getElementById(hash.replace('#', ''));
      if (element) {
        const offset = 100; // Adjust this value to scroll higher
        const elementPosition = element.getBoundingClientRect().top + window.pageYOffset;
        const offsetPosition = elementPosition - offset;

        window.scrollTo({
          top: offsetPosition,
          behavior: 'smooth',
        });
      }
    }
  }, [hash]);

  useEffect(() => {
    observerRef.current = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add('visible');
        }
      });
    });

    const cards = document.querySelectorAll('.service-card');
    cards.forEach((card) => {
      if (observerRef.current) observerRef.current.observe(card);
    });

    return () => {
      if (observerRef.current) {
        cards.forEach((card) => {
          if (observerRef.current) observerRef.current.unobserve(card);
        });
      }
    };
  }, []);

  const settings = (serviceCount: number) => ({
    dots: true,
    infinite: false, // Infinite only if more than one card
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  });

  const services = [
    {
      category: 'Clinical Services',
      id: 'clinical',
      services: [
        {
          title: 'Individual Therapy',
          description: '',
          imageUrl: '/service-images/individual-therapy.webp',
        },
        {
          title: 'Group Therapy',
          description: '',
          imageUrl: '/service-images/group-therapy.webp',
        },
        {
          title: 'Psychodrama',
          description: '',
          imageUrl: '/service-images/psychodrama.jpg',
        },
        {
          title: 'Clinical Evaluation & Diagnosis',
          description: '',
          imageUrl: '/service-images/evaluation-diagnosis.webp',
        },
        {
          title: 'Clinical Treatment',
          description: '',
          imageUrl: '/service-images/clinical-treatment.jpg',
        },
        {
          title: 'Trauma Treatment',
          description: '',
          imageUrl: '/service-images/trauma-treatment.webp',
        },
      ],
    },
    {
      category: 'Medical Services',
      id: 'medical',
      services: [
        {
          title: 'Medical Evaluation & Recommendation',
          description: '',
          imageUrl: '/service-images/medical-recommendation.webp',
        },
        {
          title: 'Medical Care',
          description: '',
          imageUrl: '/service-images/medical-care.webp',
        },
        {
          title: 'Medical Case Management',
          description: '',
          imageUrl: '/service-images/medical-case-management.webp',
        },
        {
          title: 'Referral Services',
          description: '',
          imageUrl: '/service-images/referral-services.webp',
        },
      ],
    },
    {
      category: ' Clinical Case Management',
      id: 'case-management',
      services: [
        {
          title: 'Supportive Services',
          description: '',
          imageUrl: '/service-images/supportive-services.webp',
        },
        {
          title: 'Resource Coordination',
          description: '',
          imageUrl: '/service-images/resource-coordination.jpg',
        },
        {
          title: 'Professional Collaboration',
          description: '',
          imageUrl: '/service-images/professional-collaboration.webp',
        },
        {
          title: 'After Care Planning',
          description: '',
          imageUrl: '/service-images/after-care-planning.webp',
        },
        {
          title: 'Follow up Case Management',
          description: '',
          imageUrl: '/service-images/follow-up-case-management.webp',
        },
      ],
    },
  ];
  

  return (
    <>
      <Helmet>
        <title>Our Services | Therapy, EAP, and Mental Health Care | Paragon Wellness Center</title>
        <meta name="description" content="Explore our comprehensive services including therapy, EAP, and mental health care at Paragon Wellness Center in Philadelphia." />
        <link rel="canonical" href="https://www.paragonwellness.org/services" />
      </Helmet>
      
      <section id="services-section" className="min-h-screen overflow-y-auto bg-gradient-to-b from-white via-beige to-gray-50 text-customTextColor py-20">
        <div className="container mx-auto px-4 lg:px-8">
          <div className="services-content bg-white rounded-2xl shadow-xl p-8 lg:p-12">
            <h1 className="text-4xl lg:text-5xl font-playfair font-bold mb-6 text-customTextColor text-center">
              Our Services
            </h1>
            <div className="h-1 w-52 bg-gradient-to-r from-burntOrange to-oliveGreen rounded-full mx-auto mb-8"></div>
            <p className="text-xl mb-12 max-w-3xl mx-auto text-gray-700 text-center italic">
              At Paragon Wellness Center, we offer a wide range of services to support your mental health and well-being. From our comprehensive{' '}
              <Link to="/eap" className="text-burntOrange hover:text-oliveGreen transition-colors duration-300">
                Employee Assistance Program (EAP)
              </Link>{' '}
              to individual therapy sessions, we're here to help you thrive.
            </p>

            {services.map((serviceCategory, idx) => (
              <div key={idx} id={serviceCategory.id} className="mb-24">
                <h2 className="text-3xl lg:text-4xl font-playfair font-bold mb-6 text-center text-oliveGreen">
                  {serviceCategory.category}
                </h2>
                <div className="h-1 w-40 bg-gradient-to-r from-burntOrange to-oliveGreen rounded-full mx-auto mb-12"></div>
                
                <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                  {serviceCategory.services.map((service, index) => (
                    <Link 
                      key={index}
                      to={`/services/${service.title.replace(/\s+/g, '-').toLowerCase()}`}
                      className="transform transition-all duration-300 hover:-translate-y-2"
                    >
                      <div className="service-card bg-white rounded-2xl shadow-lg overflow-hidden h-[350px] relative">
                        <div 
                          className="absolute inset-0 bg-cover bg-center transition-transform duration-300 hover:scale-110"
                          style={{ backgroundImage: `url(${service.imageUrl})` }}
                        />
                        <div className="absolute inset-0 bg-gradient-to-t from-black via-black/50 to-transparent opacity-70" />
                        <div className="absolute bottom-0 left-0 right-0 p-6 text-white">
                          <h3 className="text-2xl font-bold mb-2">{service.title}</h3>
                          {service.description && (
                            <p className="text-gray-200">{service.description}</p>
                          )}
                          <p className="text-sm text-white mt-2 font-bold">Learn more →</p>
                        </div>
                      </div>
                    </Link>
                  ))}
                </div>
              </div>
            ))}

            {/* Call to Action Section */}
            <div className="mt-16 bg-gradient-to-br from-customTextColor to-customHoverColor text-white p-8 lg:p-12 rounded-2xl shadow-xl">
              <h2 className="text-3xl font-playfair font-bold mb-6 text-center">Ready to Begin Your Journey?</h2>
              <p className="text-xl mb-8 text-center">
                Take the first step towards wellness with our comprehensive care services.
              </p>
              <div className="flex flex-col sm:flex-row justify-center gap-6">
                <button 
                  onClick={() => window.open("https://www.tebra.com/care/practice/paragon-wellness-center-llc-795719", "_blank", "noopener,noreferrer")}
                  className="group bg-burntOrange hover:bg-white text-white hover:text-burntOrange px-8 py-3 rounded-full font-bold transition-all duration-300 shadow-lg hover:shadow-xl flex items-center justify-center"
                >
                  <span className="group-hover:tracking-wider transition-all duration-300">Schedule Appointment</span>
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 ml-2" viewBox="0 0 20 20" fill="currentColor">
                    <path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd" />
                  </svg>
                </button>
                <button 
                  onClick={() => window.open('https://paragoneap.mylifeexpert.com/', '_blank', 'noopener,noreferrer')}
                  className="group bg-oliveGreen hover:bg-customTextColor text-white px-8 py-3 rounded-full font-bold transition-all duration-300 shadow-lg hover:shadow-xl flex items-center justify-center"
                >
                  <span className="group-hover:tracking-wider transition-all duration-300">EAP Member Portal</span>
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 ml-2" viewBox="0 0 20 20" fill="currentColor">
                    <path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd" />
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </div>
        <BackToTopButton />
      </section>
    </>
  );
};

export default Services;
