import React from 'react';
import { FaTimes } from 'react-icons/fa';

interface CookieConsentProps {
  onConsent: (accepted: boolean) => void;
}

const CookieConsent: React.FC<CookieConsentProps> = ({ onConsent }) => {
  return (
    <div className="fixed bottom-4 right-4 left-4 md:left-auto md:max-w-sm bg-customTextColor text-white p-4 md:p-6 rounded-lg shadow-xl z-[9999] animate-fadeIn">
      <div className="relative">
        <button 
          onClick={() => onConsent(false)} 
          className="absolute top-0 right-0 text-white hover:text-customHoverColor"
          aria-label="Close"
        >
          <FaTimes />
        </button>
        <h3 className="text-lg font-bold mb-2">Cookie Consent</h3>
        <p className="mb-4 text-sm">
          This website uses cookies to improve your browsing experience and provide personalized content. 
        </p>
        <div className="flex justify-end space-x-2">
          <button
            onClick={() => onConsent(false)}
            className="px-3 py-1 md:px-4 md:py-2 bg-gray-200 text-gray-800 rounded hover:bg-gray-300 transition-colors duration-200 text-sm"
          >
            Decline
          </button>
          <button
            onClick={() => onConsent(true)}
            className="px-3 py-1 md:px-4 md:py-2 cta-button1 transition-colors duration-200 text-sm"
          >
            Accept
          </button>
        </div>
      </div>
      <div className="absolute -bottom-2 right-4 w-4 h-4 bg-customTextColor transform rotate-45"></div>
    </div>
  );
};

export default CookieConsent;
