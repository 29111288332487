import React, { useEffect, useRef } from 'react';
import Slider from 'react-slick';
import { Link, useNavigate } from 'react-router-dom';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './About.css';
import BackToTopButton from './BackToTopButton';
import { Helmet } from 'react-helmet';

interface StaffMember {
  name: string;
  position: string;
  position2?: string;
  summary?: string;
  imageUrl: string;
  link?: string;
  category: string | string[];
}

const sortByLastName = (staffMembers: StaffMember[]): StaffMember[] => {
  return staffMembers.sort((a, b) => {
    const lastNameA = a.name.split(' ').pop()?.toLowerCase() || '';
    const lastNameB = b.name.split(' ').pop()?.toLowerCase() || '';
    return lastNameA.localeCompare(lastNameB);
  });
};

const Staff: React.FC = () => {
  const navigate = useNavigate();

  const staff: StaffMember[] = [
    { name: 'Kimberly Pakech', position: 'CEO', position2: 'LCSW, LCADC, LICSW, SAP Qualified', summary: '', imageUrl: '/staff-images/Unknown_person.jpg', link: 'https://www.tebra.com/care/provider/kimberly-pakech-mss-mlsp-lcsw-1891068920', category: ['executive', 'provider'] },
    { name: 'Ashley Meehan', position: 'CFO', position2: '(Chief Financial Officer)', summary: '', imageUrl: '/staff-images/Unknown_person.jpg', category: 'executive' },
    { name: 'Matthew Darabasz', position: 'CTO', position2: '(Chief Technology Officer)', summary: '', imageUrl: '/staff-images/matt.jpg', category: 'executive' },
    { name: 'James B. Watson', position: 'DO', position2: '(Medical Director)', summary: '', imageUrl: '/staff-images/Unknown_person.jpg', category: 'mdirector' },
    { name: 'Claire Keller', position: 'Office Manager', position2: '', summary: "Claire has been in long-term recovery from substance use for 35 years...", imageUrl: '/staff-images/claire_keller.jpg', category: 'managers' },
    { name: 'Dawn Pakech', position: 'Clinical Supervisor', position2: '', summary: '', imageUrl: '/staff-images/Unknown_person.jpg', category: 'managers' },
    { name: 'Elizabeth Higgins', position: 'Certified Recovery Specialist (CRS)', position2: 'Peer Support Specialist', summary: "Elizabeth 'Liz' Higgins is a Certified Recovery Specialist (CRS)...", imageUrl: '/staff-images/elizabeth-higgins.jpg', category: 'supportspecialist' },
    { name: 'Tina M. Wright', position: 'Therapist', position2: 'MA, CADC Certified', summary: "Tina has been practicing in the mental and behavioral health space...", imageUrl: '/staff-images/tina-wright.jpg', category: 'provider' },
    { name: 'Mark Ryan', position: 'Therapist', position2: '', summary: '', imageUrl: '/staff-images/mark-ryan.jpg', category: 'provider' },
    { name: 'Roxanne Logan', position: 'Therapist', position2: 'JD, MSS, LCSW Certified', summary: "Roxanne Logan is a therapist with law and social work degrees...", imageUrl: '/staff-images/roxanne.jpg', category: 'provider' },
    { name: 'Burnadette Bordoni', position: 'Counseling Intern', position2: '', summary: "At Paragon Wellness, we believe in social and emotional safety for all...", imageUrl: '/staff-images/burnadette.jpg', category: 'provider' },
    { name: 'Allison Lagera', position: 'Administrative Assistant', position2: '', summary: '', imageUrl: '/staff-images/Unknown_person.jpg', category: 'supportspecialist' },
  ];

  const getStaffByCategory = (category: string) => 
    sortByLastName(staff.filter(member => 
      (Array.isArray(member.category) ? member.category.includes(category) : member.category === category)
    ));

  const executives = getStaffByCategory('executive');
  const mdirector = getStaffByCategory('mdirector');
  const managers = getStaffByCategory('managers');
  const providers = getStaffByCategory('provider');
  const supportSpecialists = getStaffByCategory('supportspecialist');

  const settings = (memberCount: number) => ({
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: Math.min(memberCount, 3),
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: Math.min(memberCount, 2),
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  });

  useEffect(() => {
    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          entry.target.classList.add('visible');
        }
      });
    });

    document.querySelectorAll('.staff-card').forEach(card => observer.observe(card));

    return () => observer.disconnect();
  }, []);

  const renderStaffCards = (members: StaffMember[], isClickable: boolean) => (
    <Slider {...settings(members.length)}>
      {members.map((member, index) => (
        <div key={index} className="p-6">
          {isClickable ? (
            <Link to={`/staff/${member.name.replace(/\s+/g, '-').toLowerCase()}`}>
              <StaffCard member={member} />
            </Link>
          ) : (
            <StaffCard member={member} />
          )}
          {member.link && (
            <a
              href={member.link}
              target="_blank"
              rel="noopener noreferrer"
              className="mt-4 inline-block bg-customTextColor text-white font-semibold py-2 px-4 rounded-full hover:bg-customHoverColor"
            >
              Schedule Appointment
            </a>
          )}
        </div>
      ))}
    </Slider>
  );

  const StaffCard: React.FC<{ member: StaffMember }> = ({ member }) => (
    <div className="staff-card p-6 rounded-lg flex flex-col items-center text-center shadow-lg">
      <img src={member.imageUrl} alt={member.name} className="w-32 h-32 rounded-full mb-4 object-cover" />
      <h3 className="text-xl font-bold">{member.name}</h3>
      <p className="text-md text-gray-600">{member.position}</p>
      <p className="text-md text-gray-600">{member.position2}</p>
    </div>
  );

  const handleServicesClick = () => {
    navigate('/services');
    window.scrollTo(0, 0);
  };

  return (
    <section className="min-h-screen overflow-y-auto bg-gradient-to-b from-white via-beige to-gray-50 text-customTextColor py-20 mt-16 sm:mt-0">
      <Helmet>
        <title>About Paragon Wellness Center</title>
        <meta name="description" content="Learn about Paragon Wellness Center's mission, vision, and dedicated team of mental health professionals in Philadelphia." />
        <link rel="canonical" href="https://www.paragonwellness.org/about" />
      </Helmet>
      <div className="container mx-auto px-4 lg:px-8">
        {/* About Section */}
        <section id="about" className="mb-20">
          <div className="about-content bg-white rounded-2xl shadow-xl p-8 lg:p-12">
            <h1 className="text-4xl lg:text-5xl font-playfair font-bold mb-6 text-customTextColor text-center">
              About Us
            </h1>
            <div className="h-1 w-52 bg-gradient-to-r from-burntOrange to-oliveGreen rounded-full mx-auto mb-8"></div>
            <p className="text-xl mb-12 max-w-3xl mx-auto text-gray-700 text-center italic">
              Dedicated to providing compassionate mental health care and substance use disorder treatment in Philadelphia.
            </p>

            <div className="text-xl space-y-8 text-gray-700">
              <p className="mb-6 md:px-16">
                Paragon Wellness Center, LLC is a dynamic outpatient treatment program located in the heart of Port Richmond at 3396 Miller Street, Philadelphia, PA 19134.
              </p>
              <p className="italic mb-6 md:px-16">
                Our extensive service lines offer those suffering in silence a wide array of treatment options for healing from the loneliness and despair of substance use disorder and 
                co-occurring mental health conditions. Our clinical team will provide compassionate mental, behavioral, and emotional health and wellbeing care including trauma specific 
                treatment for those carrying tremendous stress and heartache from a one-time traumatic event or having the grave misfortune of prolonged exposure to adverse conditions.
              </p>
              <p className="italic mb-6 md:px-16">
                In addition to direct care, Paragon Wellness Center is well-placed to serve as an Employee Assistance Program (EAP) Professional Provider Organization. 
                The goal of our clinical team and supportive professional providers is to cast our net wide to reach as many lives as we possibly can in effort to effect 
                a true connection. We invite each individual to leap with courage, to search deep within, and to consider the opportunity to chart a new path however narrow 
                it may be. We are intent on serving Employers who understand the weight today’s demands have on their employees as they make a vigorous effort to balance work, 
                play, and love every day. 
              </p>
              <p className="italic mb-24 md:px-16">
                Just as a burden shared is a burden lessened, and at times even lifted, our “soul” aim is to stand ready to walk along the road to wellness with anyone willing
                enough to travel with us! 
              </p>
            </div>

            <div className="flex flex-col sm:flex-row justify-center gap-6 mt-12">
              <button 
                onClick={() => navigate('/contact#location-section')} 
                className="group bg-burntOrange hover:bg-white text-white hover:text-burntOrange px-8 py-3 rounded-full font-bold transition-all duration-300 shadow-lg hover:shadow-xl flex items-center justify-center"
              >
                <span className="group-hover:tracking-wider transition-all duration-300">Find us</span>
                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 ml-2" viewBox="0 0 20 20" fill="currentColor">
                  <path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd" />
                </svg>
              </button>
              <button
                onClick={handleServicesClick}
                className="group bg-oliveGreen hover:bg-white text-white hover:text-oliveGreen px-8 py-3 rounded-full font-bold transition-all duration-300 shadow-lg hover:shadow-xl flex items-center justify-center"
              >
                <span className="group-hover:tracking-wider transition-all duration-300">Our Services</span>
                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 ml-2" viewBox="0 0 20 20" fill="currentColor">
                  <path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd" />
                </svg>
              </button>
            </div>

            <div className="my-16 h-1 w-52 bg-gradient-to-r from-burntOrange to-oliveGreen rounded-full mx-auto"></div>

            <div className="grid md:grid-cols-2 gap-12">
              <div className="bg-white rounded-2xl shadow-xl p-8 transform hover:-translate-y-2 transition-all duration-300">
                <h3 className="text-3xl font-playfair font-bold mb-6 text-burntOrange">Mission</h3>
                <p className="text-xl text-gray-700">
                  Welcoming everyone who crosses the threshold with a warm smile and an
                  outstretched hand. Listening with empathy to ensure the conveyance of love
                  and the opportunity for healing. Offering intentional care in service to the larger
                  vision.
                </p>
              </div>
              
              <div className="bg-white rounded-2xl shadow-xl p-8 transform hover:-translate-y-2 transition-all duration-300">
                <h3 className="text-3xl font-playfair font-bold mb-6 text-oliveGreen">Vision</h3>
                <p className="text-xl text-gray-700">
                  To be experienced as a safe place for those who are hurting, where they may find the joy of
                  living through a journey that leads to health and well-being. Impacting the
                  community and its members in a meaningful way, ending the silent suffering that accompanies
                  substance use disorders and mental health conditions. Eradicating shame and fear, one person, one family at a time, with a team of
                  professionals committed to the compassionate art of understanding.
                </p>
              </div>
            </div>
          </div>
        </section>

        {/* Core Values Section */}
        <section id="core-values" className="mb-20">
          <div className="bg-white rounded-2xl shadow-xl p-8 lg:p-12">
            <h2 className="text-4xl font-playfair font-bold mb-6 text-customTextColor text-center">Our Core Values</h2>
            <div className="h-1 w-40 bg-gradient-to-r from-burntOrange to-oliveGreen rounded-full mx-auto mb-12"></div>
            
            <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
              <div className="bg-white p-8 rounded-2xl shadow-xl transform hover:-translate-y-2 transition-all duration-300">
                <h3 className="text-2xl font-bold mb-4 text-burntOrange">Compassion</h3>
                <p className="text-gray-700">We approach every individual with empathy and understanding, recognizing the unique journey of each person we serve.</p>
              </div>
              <div className="bg-white p-8 rounded-2xl shadow-xl transform hover:-translate-y-2 transition-all duration-300">
                <h3 className="text-2xl font-bold mb-4 text-oliveGreen">Integrity</h3>
                <p className="text-gray-700">We uphold the highest ethical standards in all our interactions, ensuring trust and confidentiality.</p>
              </div>
              <div className="bg-white p-8 rounded-2xl shadow-xl transform hover:-translate-y-2 transition-all duration-300">
                <h3 className="text-2xl font-bold mb-4 text-burntOrange">Innovation</h3>
                <p className="text-gray-700">We continuously seek new and effective approaches to mental health and substance use disorder treatment.</p>
              </div>
            </div>
          </div>
        </section>

        {/* Staff Section */}
        {/* <section id="staff" className="mb-20">
          <div className="bg-white rounded-2xl shadow-xl p-8 lg:p-12">
            <h2 className="text-4xl font-playfair font-bold mb-6 text-customTextColor text-center">Our Team</h2>
            <div className="h-1 w-40 bg-gradient-to-r from-burntOrange to-oliveGreen rounded-full mx-auto mb-12"></div>
            
            
          </div>
        </section> */}

        {/* Call to Action Section */}
        <section id="cta">
          <div className="bg-gradient-to-br from-customTextColor to-customHoverColor text-white p-8 lg:p-12 rounded-2xl shadow-xl">
            <h2 className="text-3xl font-playfair font-bold mb-6 text-center">Ready to Start Your Journey?</h2>
            <p className="text-xl mb-8 text-center">Take the first step towards healing and well-being with Paragon Wellness.</p>
            <div className="flex flex-col sm:flex-row justify-center gap-6">
              <button 
                onClick={() => window.open("https://www.tebra.com/care/practice/paragon-wellness-center-llc-795719", "_blank", "noopener,noreferrer")}
                className="group bg-burntOrange hover:bg-white text-white hover:text-burntOrange px-8 py-3 rounded-full font-bold transition-all duration-300 shadow-lg hover:shadow-xl flex items-center justify-center"
              >
                <span className="group-hover:tracking-wider transition-all duration-300 mr-2">Schedule Appointment</span>
                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                  <path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd" />
                </svg>
              </button>
              <button 
                  onClick={() => window.open('https://paragoneap.mylifeexpert.com/', '_blank', 'noopener,noreferrer')}
                  className="group bg-oliveGreen hover:bg-customTextColor text-white px-8 py-3 rounded-full font-bold transition-all duration-300 shadow-lg hover:shadow-xl flex items-center justify-center"
                >
                  <span className="group-hover:tracking-wider transition-all duration-300 mr-2">EAP Member Portal</span>
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                    <path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd" />
                  </svg>
                </button>
            </div>
          </div>
        </section>
      </div>
      <BackToTopButton />
    </section>
  );
};

export default Staff;