import React, { useEffect, useRef, useState } from 'react';
import BackToTopButton from './BackToTopButton';
import { Helmet } from 'react-helmet';

interface BlogPost {
  id: string;
  title: string;
  category: string;
  link: string;
  imageUrl: string;
}

const Blog: React.FC<{ blogData: BlogPost[] }> = ({ blogData }) => {
  const [selectedCategory, setSelectedCategory] = useState<string>('All');
  let filteredPosts = blogData;

  if (selectedCategory !== 'All') {
    filteredPosts = blogData.filter(post => post.category === selectedCategory);
  }

  const observerRef = useRef<IntersectionObserver | null>(null);

  useEffect(() => {
    observerRef.current = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add('visible');
        }
      });
    });

    const cards = document.querySelectorAll('.blog-card');
    cards.forEach((card) => {
      if (observerRef.current) observerRef.current.observe(card);
    });

    return () => {
      if (observerRef.current) {
        observerRef.current.disconnect();
      }
    };
  }, [filteredPosts]);

  const categories = ['All', ...Array.from(new Set(blogData.map(post => post.category)))];

  return (
    <>
      <Helmet>
        <title>Blog - Paragon Wellness Center</title>
        <meta name="description" content="Explore wellness articles and insights from Paragon Wellness Center to improve your mental health and well-being." />
        <link rel="canonical" href="https://www.paragonwellness.org/blog" />
      </Helmet>
      
      <section className="min-h-screen overflow-y-auto bg-gradient-to-b from-white via-beige to-gray-50 text-customTextColor py-20 mt-16 sm:mt-0">
        <div className="container mx-auto px-4 lg:px-8">
          <div className="blog-content bg-white rounded-2xl shadow-xl p-8 lg:p-12">
            <h1 className="text-4xl lg:text-5xl font-playfair font-bold mb-6 text-customTextColor text-center">
              Tips For a Better Tomorrow
            </h1>
            <div className="h-1 w-52 bg-gradient-to-r from-burntOrange to-oliveGreen rounded-full mx-auto mb-8"></div>
            <p className="text-xl mb-12 max-w-3xl mx-auto text-gray-700 text-center italic">
              Explore these articles and insights to improve your well-being and discover paths to a healthier lifestyle.
            </p>

            <div className="mb-12">
              <div className="flex flex-wrap justify-center gap-4">
                {categories.map(category => (
                  <button
                    key={category}
                    onClick={() => setSelectedCategory(category)}
                    className={`px-6 py-2 rounded-full font-bold transition-all duration-300 ${
                      selectedCategory === category
                        ? 'bg-burntOrange text-white'
                        : 'bg-gray-200 text-gray-600 hover:bg-oliveGreen hover:text-white'
                    }`}
                  >
                    {category}
                  </button>
                ))}
              </div>
            </div>

            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
              {filteredPosts.map((post) => (
                <a
                  href={post.link}
                  target="_blank"
                  rel="noopener noreferrer"
                  key={post.id}
                  className="no-underline transform transition-all duration-300 hover:-translate-y-2"
                >
                  <div
                    className="blog-card rounded-2xl overflow-hidden shadow-lg relative bg-white"
                    style={{
                      backgroundImage: `url(${post.imageUrl})`,
                      backgroundSize: 'cover',
                      backgroundPosition: 'center',
                      height: '350px',
                    }}
                  >
                    <div className="absolute inset-0 bg-gradient-to-t from-black to-transparent opacity-70"></div>
                    <div className="absolute bottom-0 left-0 w-full p-6 text-left">
                      <span className="inline-block bg-burntOrange text-white text-xs px-3 py-1 rounded-full mb-2">
                        {post.category}
                      </span>
                      <h3 className="text-xl font-bold text-white mb-2">{post.title}</h3>
                      <p className="text-sm text-white mt-2 font-bold">Read more →</p>
                    </div>
                  </div>
                </a>
              ))}
            </div>

            <div className="mt-16 bg-gradient-to-br from-customTextColor to-customHoverColor text-white p-8 lg:p-12 rounded-2xl shadow-xl">
              <h2 className="text-3xl font-playfair font-bold mb-6 text-center">Stay Updated</h2>
              <p className="text-xl mb-8 text-center">
                Check our blog regularly for the latest insights, tips, and resources to support your wellness journey.
              </p>
            </div>
          </div>
        </div>
        <BackToTopButton />
      </section>
    </>
  );
};

export default Blog;
