import React from 'react';

const Terms: React.FC = () => {
  return (
    <div className="bg-gradient-to-b from-beige to-white min-h-screen py-16">
      <div className="container mx-auto px-4 sm:px-6 lg:px-8 max-w-5xl">
        <h1 className="text-4xl font-bold mb-8 text-center text-gray-800">
          Terms of Use & Policies
        </h1>
        
        <div className="mb-8 bg-blue-50 border-l-4 border-blue-500 p-4 rounded-r-lg">
          <p className="text-blue-700 text-sm font-medium">
            These policies are designed to protect your rights and comply with all applicable laws, including HIPAA (Health Insurance Portability and Accountability Act).
          </p>
        </div>

        <div className="space-y-6">
          <section className="bg-white rounded-lg shadow-md p-8 border border-gray-200 hover:shadow-lg transition-shadow duration-300">
            <h2 className="text-2xl font-semibold mb-4 text-gray-800 flex items-center">
              <span className="mr-2">2.4 HIPAA Compliance</span>
            </h2>
            <div className="space-y-4">
              <p className="text-gray-700 text-sm leading-relaxed">
                As a healthcare provider, we are committed to maintaining the privacy and security of your protected health information (PHI) in accordance with HIPAA guidelines:
              </p>
              <ul className="list-disc pl-6 space-y-2 text-gray-700 text-sm">
                <li>Your medical information is kept strictly confidential and secure</li>
                <li>We maintain physical, electronic, and procedural safeguards that comply with federal regulations</li>
                <li>Access to your information is limited to authorized healthcare providers and staff who need it for your care</li>
                <li>You have the right to access, review, and request corrections to your medical records</li>
                <li>Written authorization is required for release of information except in specific circumstances required by law</li>
              </ul>
            </div>
          </section>

          <section className="bg-white rounded-lg shadow-md p-8 border border-gray-200 hover:shadow-lg transition-shadow duration-300">
            <h2 className="text-2xl font-semibold mb-4 text-gray-800">3. Cookie Policy</h2>
            <p className="text-gray-600 text-sm leading-relaxed">
              Our website uses cookies to enhance your browsing experience. By using our website, you consent to our use of cookies in accordance with this policy.
            </p>
          </section>

          <section className="bg-white rounded-lg shadow-sm p-6 border border-gray-200">
            <h2 className="text-xl font-medium mb-4 text-gray-700">4. Security</h2>
            <p className="text-gray-600 text-sm leading-relaxed">
              We implement appropriate technical and organizational security measures to protect your personal information. However, no method of Internet transmission or electronic storage is 100% secure.
            </p>
          </section>

          <section className="bg-white rounded-lg shadow-sm p-6 border border-gray-200">
            <h2 className="text-xl font-medium mb-4 text-gray-700">5. Medical Disclaimer</h2>
            <p className="text-gray-600 text-sm leading-relaxed">
              Information provided on this website is for general informational purposes only and is not a substitute for professional medical advice. Always consult your physician or qualified health provider for medical concerns.
            </p>
          </section>

          <section className="bg-white rounded-lg shadow-sm p-6 border border-gray-200">
            <h2 className="text-xl font-medium mb-4 text-gray-700">6. Non-Discrimination Policy</h2>
            <p className="text-gray-600 text-sm leading-relaxed">
              Paragon Wellness Center does not discriminate on the basis of race, color, religion, gender, gender identity or expression, sexual orientation, national origin, genetics, disability, age, or veteran status in its programs, activities, employment, and admissions.
            </p>
          </section>

          <section className="bg-white rounded-lg shadow-sm p-6 border border-gray-200">
            <h2 className="text-xl font-medium mb-4 text-gray-700">7. Copyright Policy</h2>
            <p className="text-gray-600 text-sm leading-relaxed">
              All content on this website is the property of Paragon Wellness Center or its content suppliers and is protected by copyright laws. Unauthorized use, reproduction, or distribution of the content is strictly prohibited.
            </p>
          </section>

          <section className="bg-white rounded-lg shadow-sm p-6 border border-gray-200">
            <h2 className="text-xl font-medium mb-4 text-gray-700">8. Changes to Our Policies</h2>
            <p className="text-gray-600 text-sm leading-relaxed">
              We may update our Terms of Use and Policies periodically. Any changes will be posted on this page, and you are advised to review it regularly.
            </p>
          </section>

          <section className="bg-white rounded-lg shadow-md p-8 border border-gray-200 hover:shadow-lg transition-shadow duration-300">
            <h2 className="text-2xl font-semibold mb-4 text-gray-800">1. Legal Framework</h2>
            <div className="space-y-4">
              <p className="text-gray-700 text-sm leading-relaxed">
                Our operations comply with the following laws and regulations:
              </p>
              <ul className="list-disc pl-6 space-y-2 text-gray-700 text-sm">
                <li><span className="font-semibold">HIPAA (Health Insurance Portability and Accountability Act)</span> - Protecting patient health information</li>
                <li><span className="font-semibold">GDPR (General Data Protection Regulation)</span> - For EU residents' data protection</li>
                <li><span className="font-semibold">CCPA (California Consumer Privacy Act)</span> - California residents' privacy rights</li>
                <li><span className="font-semibold">ADA (Americans with Disabilities Act)</span> - Ensuring accessibility</li>
                <li><span className="font-semibold">HITECH Act</span> - Health Information Technology standards</li>
              </ul>
            </div>
          </section>

          <section className="bg-white rounded-lg shadow-md p-8 border border-gray-200 hover:shadow-lg transition-shadow duration-300">
            <h2 className="text-2xl font-semibold mb-4 text-gray-800">2. Privacy Policy</h2>
            <div className="space-y-4">
              <h3 className="text-xl font-medium text-gray-700">2.1 Data Collection</h3>
              <p className="text-gray-700 text-sm leading-relaxed">
                We collect information including:
              </p>
              <ul className="list-disc pl-6 space-y-2 text-gray-700 text-sm">
                <li>Personal identification information</li>
                <li>Health and medical information</li>
                <li>Insurance and payment details</li>
                <li>Website usage data</li>
              </ul>

              <h3 className="text-xl font-medium text-gray-700 mt-6">2.2 Data Usage</h3>
              <p className="text-gray-700 text-sm leading-relaxed">
                Your information is used for:
              </p>
              <ul className="list-disc pl-6 space-y-2 text-gray-700 text-sm">
                <li>Providing medical services</li>
                <li>Insurance and billing purposes</li>
                <li>Legal compliance</li>
                <li>Service improvement</li>
              </ul>
            </div>
          </section>

          <section className="bg-white rounded-lg shadow-md p-8 border border-gray-200 hover:shadow-lg transition-shadow duration-300">
            <h2 className="text-2xl font-semibold mb-4 text-gray-800">9. Employment Policies</h2>
            <div className="space-y-4">
              <p className="text-gray-700 text-sm leading-relaxed">
                We comply with:
              </p>
              <ul className="list-disc pl-6 space-y-2 text-gray-700 text-sm">
                <li>Equal Employment Opportunity (EEO) regulations</li>
                <li>Fair Labor Standards Act (FLSA)</li>
                <li>Occupational Safety and Health Act (OSHA)</li>
                <li>Family and Medical Leave Act (FMLA)</li>
              </ul>
            </div>
          </section>

          <section className="bg-white rounded-lg shadow-md p-8 border border-gray-200 hover:shadow-lg transition-shadow duration-300">
            <h2 className="text-2xl font-semibold mb-4 text-gray-800">10. Financial Policies</h2>
            <div className="space-y-4">
              <ul className="list-disc pl-6 space-y-2 text-gray-700 text-sm">
                <li>Payment Card Industry Data Security Standard (PCI DSS) compliance</li>
                <li>Transparent billing practices</li>
                <li>Insurance processing procedures</li>
                <li>Financial assistance programs</li>
              </ul>
            </div>
          </section>

          <section className="bg-white rounded-lg shadow-md p-8 border border-gray-200 hover:shadow-lg transition-shadow duration-300">
            <h2 className="text-2xl font-semibold mb-4 text-gray-800">11. Dispute Resolution</h2>
            <div className="space-y-4">
              <p className="text-gray-700 text-sm leading-relaxed">
                Any disputes shall be resolved through:
              </p>
              <ul className="list-disc pl-6 space-y-2 text-gray-700 text-sm">
                <li>Initial informal resolution attempts</li>
                <li>Formal mediation if necessary</li>
                <li>Binding arbitration as a last resort</li>
                <li>Jurisdiction in the state of [Your State]</li>
              </ul>
            </div>
          </section>

          <div className="mt-12 text-center">
            <p className="text-gray-500 text-sm">
              Last updated: {new Date().toLocaleDateString()}
            </p>
            <p className="text-gray-400 text-xs mt-2">
              For questions about our policies, compliance, or legal matters, please contact our:
            </p>
            <div className="mt-4 space-y-1 text-sm">
              <p>Privacy Officer (HIPAA-related inquiries)</p>
              <p>Compliance Officer (General compliance matters)</p>
              <p>Legal Department (Legal concerns)</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Terms;
