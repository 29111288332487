import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import BackToTopButton from './BackToTopButton';

const Careers: React.FC = () => {
  useEffect(() => {
    if (window.BambooHR && typeof window.BambooHR.init === 'function') {
      window.BambooHR.init();
    }
  }, []);

  return (
    <>
      <Helmet>
        <title>Careers - Paragon Wellness Center</title>
        <meta name="description" content="Join our team at Paragon Wellness Center. Explore career opportunities and be part of our mission to transform lives through wellness." />
        <link rel="canonical" href="https://www.paragonwellness.org/careers" />
      </Helmet>
      
      <section className="min-h-screen overflow-y-auto bg-gradient-to-b from-white via-beige to-gray-50 text-customTextColor py-20">
        <div className="container mx-auto px-4 lg:px-8">
          <div className="bg-white rounded-2xl shadow-xl p-8 lg:p-12">
            <h1 className="text-4xl lg:text-5xl font-playfair font-bold mb-6 text-customTextColor text-center">
              Join Our Team
            </h1>
            <div className="h-1 w-52 bg-gradient-to-r from-burntOrange to-oliveGreen rounded-full mx-auto mb-8"></div>
            <p className="text-xl mb-12 max-w-3xl mx-auto text-gray-700 text-center italic">
              Be part of a team dedicated to transforming lives through innovative wellness solutions.
            </p>

            <div className="grid grid-cols-1 lg:grid-cols-2 gap-12 mb-16">
              {/* Career Opportunities Section */}
              <div className="bg-gradient-to-br from-white to-beige p-8 rounded-2xl shadow-lg">
                <h2 className="text-2xl font-playfair font-bold mb-6 text-customTextColor">Current Openings</h2>
                <div className="gap-8 pt-4">
                  <div
                    id="BambooHR"
                    data-domain="paragonwellness.bamboohr.com"
                    data-version="1.0.0"
                    data-departmentId=""
                    style={{ color: 'var(--tw-text-customTextColor)' }}
                  ></div>
                </div>
              </div>

              {/* Why Join Us Section */}
              <div className="bg-gradient-to-br from-white to-beige p-8 rounded-2xl shadow-lg">
                <h2 className="text-2xl font-playfair font-bold mb-6 text-customTextColor">Why Join Us?</h2>
                <div className="space-y-4">
                  <BenefitCard
                    title="Competitive Benefits"
                    description="Comprehensive health coverage, retirement plans, and paid time off"
                    icon="💼"
                  />
                  <BenefitCard
                    title="Professional Growth"
                    description="Ongoing training and development opportunities"
                    icon="📈"
                  />
                  <BenefitCard
                    title="Work-Life Balance"
                    description="Flexible scheduling and remote work options"
                    icon="⚖️"
                  />
                </div>
              </div>
            </div>

            {/* Our Values Section */}
            <div className="mt-16 bg-gradient-to-br from-customTextColor to-customHoverColor text-white p-8 lg:p-12 rounded-2xl shadow-xl">
              <h2 className="text-3xl font-playfair font-bold mb-6 text-center">Our Core Values</h2>
              <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
                <ValueCard 
                  title="Compassion" 
                  description="We approach every interaction with empathy and understanding, putting our clients' well-being first."
                  icon="❤️"
                />
                <ValueCard 
                  title="Innovation" 
                  description="We continuously seek new and improved ways to deliver exceptional wellness services and solutions."
                  icon="💡"
                />
                <ValueCard 
                  title="Integrity" 
                  description="We uphold the highest ethical standards in all our practices, fostering trust and respect in our relationships."
                  icon="🤝"
                />
              </div>
            </div>

            {/* Contact Section */}
            <div className="mt-16 text-center">
              <h2 className="text-3xl font-playfair font-bold mb-6">Questions?</h2>
              <p className="text-lg mb-4">
                Contact our HR team at{' '}
                <a 
                  href="mailto:careers@paragonwellness.org" 
                  className="text-burntOrange hover:text-oliveGreen transition-colors duration-300"
                >
                  careers@paragonwellness.org
                </a>
              </p>
            </div>
          </div>
        </div>
        <BackToTopButton />
      </section>
    </>
  );
};

const BenefitCard: React.FC<{ title: string; description: string; icon: string }> = ({
  title, description, icon
}) => (
  <div className="bg-white/80 p-6 rounded-xl shadow-md hover:shadow-lg transition-all duration-300">
    <div className="text-3xl mb-3">{icon}</div>
    <h3 className="text-lg font-bold text-customTextColor mb-2">{title}</h3>
    <p className="text-gray-700 text-sm">{description}</p>
  </div>
);

const ValueCard: React.FC<{ title: string; description: string; icon: string }> = ({
  title, description, icon
}) => (
  <div className="bg-white/10 p-6 rounded-xl backdrop-blur-sm hover:bg-white/20 transition-all duration-300">
    <div className="text-4xl mb-4">{icon}</div>
    <h3 className="text-xl font-bold mb-2">{title}</h3>
    <p className="text-white/90">{description}</p>
  </div>
);

export default Careers;
