// src/components/LotusFlower.tsx
import React, { useEffect, useState } from 'react';
import './LotusFlower.css';

const LotusFlower: React.FC = () => {
  const [petals, setPetals] = useState<Array<{
    id: number;
    style: {
      left: string;
      top: string;
      '--tx': string;
      '--ty': string;
      '--rotate': string;
      animation: string;
    };
  }>>([]);

  useEffect(() => {
    const createPetal = (id: number) => {
      const angle = (id / 12) * Math.PI * 2;
      const startDistance = 60;
      const randomOffset = Math.random() * 20;
      const distance = startDistance + randomOffset;
      
      const tx = Math.cos(angle) * distance;
      const ty = Math.sin(angle) * distance;
      
      const rotate = (angle * 180 / Math.PI) + Math.random() * 30;
      const duration = 1.2 + Math.random() * 0.3;
      const delay = (id % 4) * 0.2;

      return {
        id,
        style: {
          left: '50%',
          top: '50%',
          transform: `translate(-50%, -50%)`,
          '--tx': `${tx}px`,
          '--ty': `${ty}px`,
          '--rotate': `${rotate}deg`,
          animation: `petalFloat ${duration}s ${delay}s infinite`
        } as any
      };
    };

    const newPetals = Array.from({ length: 12 }, (_, i) => createPetal(i));
    setPetals(newPetals);

    const interval = setInterval(() => {
      setPetals(prev => prev.map(petal => createPetal(petal.id)));
    }, 4000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div id="loading-screen">
      <div className="particles">
        {petals.map(petal => (
          <div
            key={petal.id}
            className="petal"
            style={petal.style}
          />
        ))}
      </div>
      
      <div className="lotus-flower-animation" />
      
      <div className="loading-text">
        Loading...
      </div>
    </div>
  );
};

export default LotusFlower;
